import logo from '../assets/images/sunbeam-logo.png'; // with import
//const logo = require('../assets/images/sunbeam-logo.png'); // with require



function Header() {
    return (
        <>
<div className="fixed-top container-fluid bg-secondary" style={{color: "whitesmoke", textAlign: "right"}}>
  <i className="bi bi-telephone"></i> Pune: +91 82 82 82 9806 &nbsp;&nbsp;&nbsp;
</div>      
<br/>
<nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light" aria-label="Fifth navbar example">
    <div className="container-fluid">
      <a className="navbar-brand" href="https://sunbeaminfo.in" target="_blank"><img src={logo} /></a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExample05">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">Home</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
        </>
);
}

export default Header;
