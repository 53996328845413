
function StudentInfo({student}) {
  console.log(student)
  return (
      
        <>
        <div className="container">
       <table className="table table-striped">
        <tbody>
      <tr>
      <th scope="row">Roll No</th>
        <td>{(student!=null?student.RegistrationNo:"")}</td>
        </tr><tr>
        <th scope="row">Name</th>
        <td>{student!=null?student.NameOnCertificate:""}</td>
        </tr><tr>
        <th scope="row">Batch</th>
        <td>{student!=null?student.BatchName:""}</td>
        </tr><tr>
        <th scope="row">Email-Id</th>
        <td>{student!=null?student.Email:""}</td>
        </tr><tr>
        <th scope="row">Mobile No.</th>
        <td>{student!=null?student.MobileNo:""}</td>

      </tr>
      </tbody>
</table></div>
        </>
);
}

export default StudentInfo;
