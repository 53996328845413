function Footer() {
    return (
        <>
        <nav className="navbar fixed-bottom navbar-light bg-light">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">Sunbeam. © {(new Date()).getFullYear()}. All Rights Reserved.</a>
  </div>
</nav>
        </>
);
}

export default Footer;
