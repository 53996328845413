import axios from "axios";
import config from "../config/webapp.config.json";

export function feedbackApiService() {
  const baseURL = config.baseApiUrl;
  feedbackApiService.prototype.saveFeedback = async function (feedback) {
    debugger
    try {
      feedback["IsAdmissionTaken"]=(feedback["IsAdmissionTaken"]==="Y")
      feedback["RecommendStatus"]=(feedback["RecommendStatus"]==="Y")
      feedback["LastModifiedBy"]="";

      if(feedback["CCATRankAB"]==="0")
      {
        feedback["IsAdmissionTaken"] = false;
        feedback["Reason"] = "Not Eligible";
        feedback["CCATRankABC"] = "0";
        feedback["CdacCourseId"] = "0";
        feedback["CdacCentreId"] = "0";
      }
      console.log(feedback)
      const response = await axios.post(`${baseURL}/FeedbackAPI/Save`,feedback,
        {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      )
      return response.data;
    } catch (error) {
      return error;
    }
  };

  feedbackApiService.prototype.getStudent = async function (email) {
    try {
      const response = await axios.get(
        `${baseURL}/FeedbackAPI/GetStudent/?email=${email}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  feedbackApiService.prototype.getCdacCentres = async function () {
    try {
      const response = await axios.get(`${baseURL}/FeedbackAPI/GetCdacCentres`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  feedbackApiService.prototype.getCdacCourses = async function () {
    try {
      const response = await axios.get(`${baseURL}/FeedbackAPI/GetCdacCourses`);
      return response.data;
    } catch (error) {
      return error; // You might want to handle the error differently depending on your needs
    }
  };
}
