import logo from './logo.svg';

import Header from './components/header';
import Footer from './components/footer';
import FeedbackForm from './pages/FeedbackForm';

function App() {
  return (
    <>
    <Header></Header>
    <FeedbackForm></FeedbackForm>
      <Footer></Footer>
      </>
  );
}

export default App;
